import { CountryCode } from "constants/store";

export interface ValueProp {
  value: string | number;
}

export interface WindowInterface extends Window {
  webkit?: any,
  OpenSeadragon?: any,
  resendTimerId?: any,
}

export interface RedirectRoute {
  pathname: string,
  query?: any,
}

export interface ShippingChargeItem {
  rvpCharge: number,
  rtoCharge: number,
  shippingPartner: string,
}

export interface Product {
  addedToAllStores: boolean,
  productId: number,
  productName: string,
  productPrice: number,
  couponDiscountedPrice: number,
  productOriginalPrice: number,
  discount: string,
  imageUrls: string[],
  videoUrl?: string,
  videoUrlArray: { videoUrl: string; thumbnailUrl: string, videoId: number }[];
  catalogueRating: number,
  catalogueRatingCount: number,
  sizes: string[],
  productDescription: string,
  totalQuantity?: number,
  variantLevelInventories?: any[],
  isBanner?: boolean,
  productShippingChargeDetails: { shippingChargeList: ShippingChargeItem[] },
  supplierRating: number;
  maxSupplierRating: number;
  productWeight: number;
  collectionId?: number,
  collectionName?: string,
  collectionIcon?: string,
  productPriceData: {
    price: number,
    currency: string,
    formattedPrice: string
  },
  couponDiscountedPriceData: {
    price: number,
    currency: string,
    formattedPrice: string
  },
  productOriginalPriceData: {
    price: number,
    currency: string,
    formattedPrice: string
  },
  videoOffset?: number,
  pushToShopifyEnabled: boolean,
  productCode?: string,
  gstPercentage?: string,
  gstAmount?: number,
  visenzeReqId?: string,
  visenzeImId?: string,
  visenzePos?: number,
  pushToShopifyError?: string | null,
  wishlisted?: boolean,
  rank?: number,
  productRank?: number,
  visibleShippingCharge: number,
  automatedProductTags: {
    orderTag: number[],
    deliveryTag: string,
  },
  manualProductTags: ManualProductTag[],
  campaignInsightsFlag?: boolean,
  isHeroProductTesting?: boolean,
}

export interface ManualProductTag {
  count: number,
  countSuffix: string,
  redirectionUrl: string,
  type: string,
  descriptionText: string,
  logoUrl: string,
  displayText: string,
}

export interface StoreSummary {
  numberOfOrdered?: number,
  numberOfNewOrders?: number,
  numberOfApprovedOrders?: number,
  numberOfPickupRequestedOrders?: number,
  numberOfShippedOrders?: number,
  numberOfPaymentPendingOrders?: number,
  numberOfClosedOrders?: number,
  numberOfDeliveredOrders?: number,
  // In transit order stats
  numberOfPendingDeliveryOrders?: number,
  numberOfOutForDeliveryOrders?: number,
  numberOfFailedDeliveryOrders?: number,
}

export interface Tab {
  id: number,
  name: string,
  status?: string,
  countKey?: string,
  subTabs?: Tab[],
}

export interface Status {
  id: string,
  name: string,
}

export interface OrderDetail {
  guestOrder: boolean,
  customerAddress: string,
  orderCustomerAddress: string,
  orderCustomerCity: string,
  orderCustomerEmail: string,
  orderCustomerName: string,
  orderCustomerPhone: string,
  orderCustomerPincode: string,
  orderDateTime: number,
  orderId: number,
  orderTimestamp: number,
}

export interface OrderRowData {
  saasChannel: string,
  saasOrderId: number,
  saasOrderName: string,
  orderPaymentMode: string,
  orderDetails: OrderDetail,
}

export interface OrderColumnData {
  id?: string;
  Header: string;
  accessor: string | any;
  customWidth?: string;
  Cell?: { (value: string | number, row: OrderRowData): any };
  Filter?: { (column: OrderColumnData): any | undefined };
  canFilter?: boolean;
}

export interface BankDetails {
  accountHolderName: string;
  bankAccountNumber: number;
  bankIFSCCode: string;
  bankDetailProof: any;

  /** masked fields */
  maskedAccountHolderName: string;
  maskedBankAccountNumber: string;
  maskedBankIFSCCode: string;
}

export interface PersonalDetails {
  name: string;
  phoneNumber: string;
  emailAddress: string;
  channelStoreName: string;
}

export interface Collection {
  collectionId: number,
  collectionName: string,
  banner: string,
  products: Product[],
  paginationKey?: number,
  totalProducts: number,
  collectionIcon?: string,
}

export interface Category {
  categoryId: string;
  categoryName: string;
  imageUrl: string;
}

export interface SearchSuggestion {
  suggestedText?: string,
  subcategoryName: string,
  mastercategoryName: string,
  categoryName: string,
  showCategoryTree?: boolean,
  isAutocorrect: boolean,
  categoryTree?: string,
}

export interface SearchResultData {
  product_id?: number;
  product_name: string;
  product_description?: string;
  product_code: string;
  product_weight?: string;
  wholesale_price?: number;
  supplier_rating?: number;
  supplier_rating_count?: number;
  total_product_variant_quantity?: number;
  image_ids?: number[];
  image_urls?: string[];
  catalogue_id?: number;
  master_product_category_name?: string;
  master_product_category_id?: number;
  videocdn?: string[];
}

export interface CompletionResult {
  text: string;
  search_result_data: SearchResultData;
}



export interface GSTDetails {
  gstName: string;
  gstId: string;
  gstPan: string;
  gstPhotoProof: any;
  panPhotoProof: any;

  /** masked fields */
  maskedGstId: string;
  maskedGstPan: string;
}

export interface ReduxAction {
  payload: any,
  type: string,
}

export const SET_SHOPIFY_STORE_DATA = 'SET_SHOPIFY_STORE_DATA';
export const SET_SHOPIFY_STORE_ERROR = 'SET_SHOPIFY_STORE_ERROR';

export type CountryPreference = { code: CountryCode; label: string; id: number }

export interface userReducer {
  shopifyStoreData: {
    shopifyStores: ShopifyStore[],
    showOtherStore: boolean,
    storeMobileNumber: number,
    countriesData: {countryName: string; countryId: number}[],
    name:string,
    emailAddress:string,
    phoneNumber:string,
    isB2BStore: boolean,
    b2bUpdationTime: number,
    isInvestorStore: boolean,
    isReviewStore: boolean,
    collectionIdForHeroProductTesting: number,
  },
  storesFetched: boolean,
  error: Object,
  selectedCountry: CountryPreference,
  whatsappSubscriptionDetails: {
    isWhatsappDashboardEnabled: boolean,
    subscriptionEndedOn: null | string,
    subscriptionStartedFrom: null | string,
    isInvestorStore: null | boolean,
  },
  imageSearchData: ImageSearchData,
  pdpActiveNav: string;
}

interface productsReducer {
  productCategories: Category[];
  productCollections: {}[];
  productFeed: { [key: string]: any };
  wishlist: { 
    isFetching: boolean;
    products: Product[];
    totalProducts: number;
    isError: boolean;
    [key: string]: any;
  };
}

export interface ReduxState {
  user: userReducer,
  product: productsReducer,
  drawer: DrawerState,
  portraitProductVideoPlayer: PortraitProductVideoPlayerState,
}

export interface ShopifyStore {
  defaultStore: boolean,
  linkedOn: number,
  platformImageUrl: string,
  storeName: string,
  storeUrl: string,
  storesPrimaryKey: number,
  isPushed?: boolean,
}

export interface PaymentCheckoutCallback {
  status: 'SUCCESS' | 'FAILED' | 'DISMISS';
}

export interface SidebarNavProp { 
  id: string;
  label: string; 
  link?: string;
  icon?: string;
  iconLight?: string;
  isHome?: boolean;
  isPopup?: React.ReactNode;
  tooltipText?: string;
  childNavs?: {
    id: string,
    label: string,
    link: string,
    navId: string,
    icon?: string,
    countryAccess: CountryCode[],
    permission?: string
  }[];
  navId: string;
  countryAccess: CountryCode[];
  permission?: string;
}

export interface SourceProductPayload {
  userId: string | number;
  categoryId: number;
  productName: string;
  productURL?: string;
  images?: Array<string>;
  expectedCloutPrice: number;
  expectedDailyOrders: string;
}

export interface RtoFAQ {
  question: string;
  answer: string;
  showRecommendationTable?: boolean;
}

export interface RtoRiskAndAction {
  id: number;
  reason: string;
  action: string;
  details: string;
}


export interface RTOProbablityFAQ {
  faqs: Array<RtoFAQ>;
  riskAndActions: Array<RtoRiskAndAction>;
}

export interface RTOProbablityReason {
  id: string;
  title: string;
  options: Array<{ id: string | number; reason: string; }>
}

export interface ReportData {
  reportName: string;
  reportType: string;
  requestDate: number;
  reportStartDate: number;
  reportEndDate: number;
  status: string;
  reportId: number;
}

export interface ProductDetail {
  productId: number;
  productName: string;
  productCode: string;
  imageUrl: string;
  shopifyProductId: number;
  productShopifyUrl: string;
  pushedAt: string;
  cloutPrice: number;
  sellingPrice: number;
  totalInventory: number;
  inventories: any[];
  productCluster: any[];
  isInventoryRequested: boolean;
  requestId: number | null;
}

export interface PaymentDetails {
  paymentDate: string;
  paymentDay: string;
  paymentCycleType: string;
  transactionId: string;
  amount: number;
  paymentFileLink: string;
}

export interface PaymentData {
  totalAmount: number;
  startPaymentDate: string;
  paymentDetails: Array<PaymentDetails>;
}

export interface FailedToSyncReason {
  reasonId: number;
  reasonType: string;
}

export interface NDRHistoryListProps {
  ndrTimestamp: number | Date;
  phone: string;
  status: string;
  reason: string;
  address: string;
  reattemptCount: string;
  nextAttemptDate: string;
  iconType: string;
}

export interface NDRStatusColor {
  text: { [key: string]: string };
  bg: { [key: string]: string };
}

export interface InventoryRequestDetail {
  productId: number,
  productName: string,
  productCode: string,
  imageUrl: string,
  requestDate: string,
  requestId: number,
  storeId: number,
  storesPushedTo: string[],
  expectedCloutPrice: number,
  expectedDailyOrders: number,
  expectedCampaignDuration: number,
  cloutPrice: number,
  totalInventory: number,
  isActive: boolean,
}

export interface NotificationProps {
  type?:string;
  inventoryInfo?: boolean;
  notificationId: number;
  timestamp: string;
  header: string;
  subHeader?: string;
  content: {
    productImage: string;
    productName: string;
    oldPrice: number;
    newPrice: number;
    oldDiscountedPrice: number;
    newDiscountedPrice: number;
    marginDifference: number;
    channelStoresPrimaryKey: string; 
    channelStoreHandle: string;
    productCode: string;
    newProductCode: number;
    oldProductCode: number;
    quantity: number;
    oldProductSupplierRating: number;
    newProductSupplierRating: number;
    oldProductQuantity: number;
    newProductQuantity: number;
    priceChangeType: string;
    productStockType: string;
    stockTypeEnum: string[];
  };
  redirectUrl: string;
  color: string;
  icon: string;
  read: boolean;
}
export interface deliveryStateListParams {
  startDate: number;
  paymentMethod: string;
  orderStatus: string;
  productId: number;
  endDate: string;
  storesPrimaryKey: number;
  sortBy: string;
  sortDir: string;
}
export interface DrawerState {
  [key: string]: { isVisible: boolean; data: any };
}

export interface PortraitProductVideoPlayerState {
  videoPlayerType?: string;
  videoFeedType?: string;
  isVisible: boolean;
  products: Product[];
  headerName?: string;
  headerIcon?: string;
  redirectUrl?: string;
  [key: string]: any;
}

export interface staffListing {
  id:string,
  creation_date:string,
  name:string,
  email:string,
  last_login:string,
  current_status:string,
  color:string,
  darkerColor:string,
  status:string,
}

export interface ShoptabVideoFeedI {
  coverImage: ShoptabMedia;
  productDetails: ShoptabProduct;
  title: string;
  video: ShoptabMedia;
}

export interface ShoptabProduct {
  catalogueId: number;
  cta: {
    bgColor: string;
    text: string;
    url: string;
  },
  discountPercent: string;
  originalPrice: string;
  productCode: string;
  productId: number;
  productImage: ShoptabMedia;
  rating: string;
  sellingPrice: string;
  title: string;
}

export interface ShoptabMedia {
  mediaType: string;
  shapeType: string;
  url: string;
}

export interface ImageSearchData {
  productDetails: Product[];
  productMetaData: {
    imageHash: string;
    visibleProductCount: number;
  };
  visenzeProductData: any[];
  imageFileUrl: string;
}
