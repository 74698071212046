import {apiCall, paramsToString, iteratedParamsToString} from "utils/api";
import {sellerStoreId} from "utils/auth";
import {NextPageContext} from "next";
import {SearchSuggestion, SourceProductPayload} from 'types';
import { toast } from "react-toastify";
import { CountryCode, COUNTRY_CODE } from "constants/store";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/shopify`;

export const shopifyAuth = async (data: any) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/admin/auth`,
    data,
  })
  return res.data;
}

export const getShopifyFeed = async (paginationKey: any = '') => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/supplyFeed/all?paginationKey=${paginationKey}`,
  })
  return res.data;
}

export const addToShopify = async (data: any, storesPrimaryKey: number) => {
  console.log(data,storesPrimaryKey);
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/products?storesPrimaryKey=${storesPrimaryKey}`,
    data,
  })
  return res.data;
}

export const getShopifyConfig = async ({ctx, shopifyHmac, shopifyShopUrl}:{ctx: NextPageContext, shopifyHmac: string, shopifyShopUrl: string }) => {
  const res = await apiCall({
    ctx,
    method: 'get',
    url: `${HOST}/auth/init?shopifyHmac=${shopifyHmac}&shopifyShopUrl=${shopifyShopUrl}`,
  })
  return res.data;
}

export const getMarginDetails = async (productId: number, productPrice: number) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/products/${productId}/margin?productPrice=${productPrice}`,
  })
  return res.data;
}

export const getShopifyIntegrationStatus = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/status`,
  })
  return res.data;
}

export const getProductDetails = async ({ctx}:{ctx: NextPageContext}, countryCode = COUNTRY_CODE.india) => {
  const productId = ctx.query.productId;
  const res = await apiCall({
    ctx,
    method: 'get',
    url: `${HOST}/products/${productId}?${paramsToString({
      countryCode
     })}`,
  })
  return res.data;
}

export const getProductDetailsByProductId = async (productId: number | string, countryCode: string = COUNTRY_CODE.india) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/products/${productId}?${paramsToString({
      countryCode
     })}`,
  })
  return res.data;
}

export const approveShopifySuborders = async (suborderIds: number[]) => {
  const data = {
    suborderIds,
  };
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/stores/${sellerStoreId()}/suborders/approve`,
    data,
  })
  return res.data;
}

export const cancelShopifySuborders = async (suborderIds: number[]) => {
  const data = {
    suborderIds,
  };
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/stores/${sellerStoreId()}/suborders/cancel`,
    data,
  })
  return res.data;
}

export const getCollections = async ({ limit, collectionLimit, collectionOffset, showHeroProductCollection }: { limit?: number; collectionLimit?: number; collectionOffset?: number, showHeroProductCollection?: boolean }) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/collections?${paramsToString({
      ...((limit || limit === 0) && { limit }),
      ...((collectionLimit || collectionLimit === 0) && { collectionLimit }),
      ...((collectionOffset || collectionOffset === 0) && { collectionOffset }),
      ...((showHeroProductCollection) && { showHeroProductCollection }),
    })}`,
  })
  return res.data;
}

export const getCollectionDetails = async (collectionId: string | number, offset: any = '', limit: any = '', sortType: any = '', tilesInRow: number = 5, showBanner: boolean = true) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/collections/${collectionId}/products?offset=${offset}&limit=${limit}&sortType=${sortType}&tilesInRow=${tilesInRow}&showBanner=${showBanner}`,
  })
  return res.data;
}

export const getCategories = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/categories`,
  });

  return res.data;
};

export const getCategoryFeed = async (categoryId: string, limit: any = '', paginationKey: number = 0, tilesInRow: number = 5, showBanner = true, videoOffset: number | null = null) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/supplyFeed/categories?categoryId=${categoryId}&limit=${limit}&paginationKey=${paginationKey}&tilesInRow=${tilesInRow}&showBanner=${showBanner}${videoOffset ? `&videoOffset=${videoOffset}` : ''}`,
  });

  return res.data;
};

export const getCategoryVideos = async (categoryId: string, offset: number = 0, limit: any = '') => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/supplyFeed/categories/video?categoryId=${categoryId}&paginationKey=${offset}&limit=${limit}`,
  });

  return res.data;
};

export const getCatalogSearchFeed = async (suggestionObject: SearchSuggestion, offset: any, limit: any, sortType: any = '', countryCode: CountryCode, tilesInRow: number = 5, showBanner = true, videoOffset: number | null = null) => {
  const response = await apiCall({
    method: 'get',
    headers: {
      'content-type': 'application/json'
    },
    url: `${HOST}/catalog/search/v2?${paramsToString({
      offset,
      limit,
      sortType,
      tilesInRow,
      catalogueName: suggestionObject.suggestedText,
      category: suggestionObject.categoryName,
      subCategory: suggestionObject.subcategoryName,
      leafCategory: suggestionObject.mastercategoryName,
      isAutocorrect: suggestionObject.isAutocorrect,
      countryCode,
      showBanner,
      ...(videoOffset ? {videoOffset} : {})
    })}`,
  })
  return response.data;
}

export const getCatalogVideoSearchFeed = async ({suggestionObject, offset, limit, countryCode} : {suggestionObject: SearchSuggestion, offset: number, limit: number, countryCode: CountryCode}) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/catalog/videoSearch?${paramsToString({
      offset,
      limit,
      catalogueName: suggestionObject.suggestedText,
      category: suggestionObject.categoryName,
      subCategory: suggestionObject.subcategoryName,
      leafCategory: suggestionObject.mastercategoryName,
      isAutocorrect: suggestionObject.isAutocorrect,
      countryCode: countryCode || COUNTRY_CODE.india,
    })}`,
  })
  return response.data;
}

export const getShopifySyncStatus = async (storesPrimaryKey = 0) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/syncStatus?${paramsToString({
      storesPrimaryKey
    })}`,
  })
  return res.data;
}

export const setShopifyOrdersSync = async (days: string, storesPrimaryKey = 0) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/sync/dropshippingOrders?${paramsToString({
      days,
      storesPrimaryKey,
    })}`,
  })
  return res.data;
}

export const getNotSyncedOrders = async (offset = 0, limit = 10, storesPrimaryKey = 0, filterParams = {}, countryCode = COUNTRY_CODE.india) => {
  const response = await apiCall({
    method: 'get',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/shopifyDropShip/stores/${sellerStoreId()}/notSyncedOrders?${iteratedParamsToString({
      countryCode,
      limit,
      offset,
      storesPrimaryKey,
      ...filterParams,
    })}`,
  })
  return response.data;
}

export const removeNotSyncedOrder = async (channelOrderId: number, storesPrimaryKey = 0, removeInBulkResync = false) => {
  const response = await apiCall({
    method: 'delete',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/shopifyDropShip/stores/${sellerStoreId()}/order?${paramsToString({
      channelOrderId,
      storesPrimaryKey,
      removeInBulkResync,
    })}`,
  })
  return response.data;
}

export const resyncNotSyncedOrder = async (channelOrderId: number, storesPrimaryKey = 0) => {
  const response = await apiCall({
    method: 'post',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/shopifyDropShip/stores/${sellerStoreId()}/resyncOrder?${paramsToString({
      channelOrderId,
      storesPrimaryKey,
    })}`,
  })
  return response.data;
}

interface InitiateRazorpayPaymentData {
  orderId: number | string;
  amount : number | string;
}

interface InitiateRazorpayBulkPaymentData {
  orderDetailsList: InitiateRazorpayPaymentData[];
  totalAmount: string | number;
}

export const initiateRazorpayPayment = async (data: InitiateRazorpayPaymentData | InitiateRazorpayBulkPaymentData, isBulkPayment: boolean = false) => {
  try {
    const res = await apiCall({
      method: 'post',
      url: `${HOST}/razorpay/initiatePayment/${isBulkPayment ? 'bulk' : ''}`,
      data: { ...data, storeId: sellerStoreId() },
    })
  
    return res.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);
  } 
}

export const cancelRazorpayPayment = async (data: any) => {
  try {
    const res = await apiCall({
      method: 'put',
      url: `${HOST}/razorpay/cancelPayment`,
      data,
    });

    return res.data;
  } catch (err) {
    const res = err.response;
    toast.error(res?.data?.status);
  } 
};

export const getShopifyStoreData = async () => {
  const response = await apiCall({
    method: 'get',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/stores/${sellerStoreId()}/shopify-stores`,
  })
  return response.data;
}

export const updateShopifyStoreDetails = async (storesPrimaryKey = 0, storeName: string, defaultStore: boolean) => {
  const response = await apiCall({
    method: 'put',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/stores/${sellerStoreId()}/shopify-store-detail`,
    data: {
      storesPrimaryKey,
      storeName,
      defaultStore,
    },
  })
  return response.data;
}

export const getProductShopifyStoreLinkDetails = async (productId: number) => {
  const response = await apiCall({
    method: 'get',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/products/${productId}/store-details?storeId=${sellerStoreId()}`,
  })
  return response.data;
}

export const getShopifyStoreLinkedStatus = async (shopName: string,) => {
  const response = await apiCall({
    method: 'post',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/admin/store/linkStatus`,
    data: {
      shopName,
    },
  })
  return response.data;
}

export const sourceProduct = async (data: SourceProductPayload) => {
  try {
    const res = await apiCall({
      method: 'post',
      url: `${HOST}/stores/${sellerStoreId()}/productSourcing`,
      data,
    });

    return res.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);
  }
}

export const productSourcingEmailNotify = async (requestId: number | string, emailId: string) => {
  const response = await apiCall({
    method: 'post',
    headers: {
      'business-type': 'shopifyDropshippingPlugin',
      'content-type': 'application/json'
    },
    url: `${HOST}/productSourcing/notify?${paramsToString({
      requestId,
      emailId,
    })}`,
  })
  return response.data;
}

export const getHighRtoPincodeList = async () => {
  const res = await apiCall({
    method: 'get',
    responseType: 'blob',
    url: `${HOST}/rto/download`,
  });

  return res.data;
};

export const getHighRtoLastUpdateTime = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/rto/date`,
  });

  return res.data;
};

export const exportNotSyncedOrdersReport = async (storesPrimaryKey: number, lastNDays: number, countryCode = COUNTRY_CODE.india) => {
  const response = await apiCall({
    method: 'get',
    headers: { 'business-type': 'shopifyDropshippingPlugin' },
    responseType: 'blob',
    url: `${HOST}/shopifyDropShip/stores/${sellerStoreId()}/notSyncedOrders/download?${paramsToString({
      countryCode,
      storesPrimaryKey,
      lastNDays,
    })}`,
  })
  return response.data;
};

export const getDashboardRevenueOverview = async (params: any) => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/revenueOverview?${paramsToString(params)}`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
};

export const getDashboardOrderDistributionView = async (params: any) => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/orderDistributionView?${paramsToString(params)}`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
};

export const getDashboardOrderStatus = async () => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/orderStatusFilter`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
};

export const getDashboardProductList = async (params: any) => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/productLevelView?${paramsToString(params)}`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
};

export const getDashboardProductOptionList = async (params: any) => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/ProductDetails?${paramsToString(params)}`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
}

export const getDashboardOrdersOverview = async (params: any) => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/ordersOverview?${paramsToString(params)}`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
};

export const getCloutUpdatePosts = async (postType: string = '', limit: number = 0, paginationKey: number = -10000000) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/cloutPost?${paramsToString({
      ...(postType && { postType }),
      limit,
      paginationKey,
    })}`,
  });

  return res.data;
};

export const markCloutUpdatePostAsRead = async (latestPostId: number) => {
  const res = await apiCall({
    method: 'put',
    url: `${HOST}/stores/${sellerStoreId()}/cloutPost?${paramsToString({ latestPostId })}`,
  });

  return res.data;
};

export const getMasterReportsCount = async (storesPrimaryKey: number) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/reportsCount?${paramsToString({ storeId: sellerStoreId(), storesPrimaryKey })}`,
  });

  return response.data;
};

export const getMasterReportsData = async (storesPrimaryKey: number, offset: number = 0, limit: number = 10): Promise<any> => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/reportDetails?${paramsToString({
      storeId: sellerStoreId(),
      storesPrimaryKey,
      offset,
      limit,
    })}`,
  });

  return response.data;
};

export const downloadMasterReport =async (reportId: number) => {
  const res = await apiCall({
    method: 'get',
    responseType: 'blob',
    url: `${HOST}/masterReport/download?${paramsToString({ storeId: sellerStoreId(), reportId })}`,
  });

  return res.data;
};

export const getShopifyProducts = async (queryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/productDetails?${paramsToString(queryParams)}`,
  });

  return response.data;
};

export const updateShopifyPrice = async (storesPrimaryKey: number) => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/stores/${sellerStoreId()}/updateShopifyPrice?storesPrimaryKey=${storesPrimaryKey}`,
  });
  
  return response.data;
};

export const getPaymentsData = async (queryParams: any): Promise<any> => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/paymentDetails?${paramsToString(queryParams)}`,
  });

  return response.data;
};

export const getPaymentSheetMetaData = async () => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/paymentSheetMetaData`,
  });

  return response.data;
};

export const getFailedToSyncReasons = async (countryCode?: CountryCode) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/failedToSyncReason?${paramsToString({ 
      countryCode: countryCode || COUNTRY_CODE.india  
    })}`,
  });

  return res.data;
};

export const bulkRemoveNotSyncedOrders = async (orderIds: number[], storesPrimaryKey = 0, removeInBulkResync = false) => {
  const response = await apiCall({
    method: 'delete',
    url: `${HOST}/stores/${sellerStoreId()}/orders?${paramsToString({ storesPrimaryKey, removeInBulkResync })}`,
    data: orderIds,
  })
  return response.data;
};

export const bulkReSyncNotSyncedOrders = async (orders: any[], storesPrimaryKey = 0) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/stores/${sellerStoreId()}/bulkResync?storesPrimaryKey=${storesPrimaryKey}`,
    data: orders,
  });

  return response.data;
};

export const updateAutoConfirmOrders = async (markAutoApprove: boolean) => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/admin/${sellerStoreId()}/markAutoApproveStores?${paramsToString({
      markAutoApprove,
    })}`,
  });

  return response.data;
};

export const getFailedToSyncLastSyncDaysNumber = async () => {
  try {
    const response = await apiCall({
      method: 'get',
      url: `${HOST}/shopifyDropShip/stores/${sellerStoreId()}/lastSyncDaysData`,
    });
  
    return response.data;
  } catch (err) {
    const res = err.response;

    toast.error(res?.data?.status);

    throw err;
  }
};

export const updateNdr = async ({
  ndrRequest,
  rtoRequest,
  orderId,
  rtoReason,
  addressUpdated,
  } : { 
    ndrRequest?: any;
    rtoRequest?: boolean;
    orderId?: number;
    rtoReason?: string;
    addressUpdated?: boolean;
  }) => {
  const data = {
    ...(ndrRequest && { ndrRequest }),
  }
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/ndr/update?${paramsToString({
      ...(rtoRequest && { rtoRequest }),
      ...(orderId && { orderId }),
      ...(rtoReason && { rtoReason }),
      ...(!rtoRequest && ndrRequest && { addressUpdated }),
    })}`,
    data,
  });

  return res.data;
}

export const getNDRHistory = async (trackingNumber: boolean) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/ndr/${trackingNumber}/history`,
  });

  return response.data;
};

export const getInventoryRequests = async (queryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/inventory/details/${sellerStoreId()}?${paramsToString(queryParams)}`,
  });

  return response.data;
};

export const editInventoryRequest = async (requestId: number, data: any) => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/stores/inventory/request/${requestId}`,
    data,
  });

  return response.data;
};

export const createInventoryRequest = async (data: any) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/stores/inventory/request/${sellerStoreId()}`,
    data,
  });

  return response.data;
};

export const getProductInventoryRequest = async (requestId: number) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/inventory/details/request/${requestId}`,
  });

  return response.data;
};

export const getDetailsForProduct = async (queryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/inventory/detailsForProduct/${sellerStoreId()}?${paramsToString(queryParams)}`,
  });

  return response.data;
};

export const getDeliveryStateList = async (deliveryStateListQueryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/stateView?${paramsToString(deliveryStateListQueryParams)}`,
  });

  return response.data;
};

export const getDeliveryStateListByProductId = async (productId: number, deliveryStateListQueryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/products/${productId}?${paramsToString(deliveryStateListQueryParams)}`,
  });

  return response.data;
};

export const getPlatformMarginList = async (queryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/platform/marginView?${paramsToString(queryParams)}`,
  });

  return response.data;
};

export const getStoreMarginList = async (queryParams: any) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${sellerStoreId()}/cloutDashboard/marginView?${paramsToString(queryParams)}`,
  });

  return response.data;
};

export const updateAnalyticsCalculationPreference = async (data: any) => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/stores/${sellerStoreId()}/analyticsTabSetting`,
    data,
  });

  return response.data;
};

export const validateNDRSheet = async (storesPrimaryKey: string | number, data: any) => {
  const response = await apiCall({
    method: 'post',
    headers: { 'content-type': 'multipart/form-data; boundary=--ndrSheetFileBoundary' },
    url: `${HOST}/ndr/bulk/validateSheet?${paramsToString({ storeId: sellerStoreId(), storesPrimaryKey })}`,
    data,
  });

  return response.data;
};

export const submitBulkNDRSheet = async (storesPrimaryKey: string | number, data: any) => {
  const response = await apiCall({
    method: 'post',
    headers: { 'content-type': 'multipart/form-data; boundary=--ndrSheetFileBoundary' },
    url: `${HOST}/ndr/bulk/processSheet?${paramsToString({ storeId: sellerStoreId(), storesPrimaryKey })}`,
    data,
  });

  return response.data;
};

export const getBulkNDRReports = async (storesPrimaryKey: string | number) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/ndr/bulk/reportDetails?${paramsToString({ storeId: sellerStoreId(), storesPrimaryKey })}`,
  });

  return response.data;
};

export const getNDRFlags = async (storesPrimaryKey: number | string) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/ndr/bulk/flag?${paramsToString({ storeId: sellerStoreId(), storesPrimaryKey })}`,
  });

  return res.data;
};

export const markLatestReportVisited =  async (storesPrimaryKey: number | string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/ndr/bulk/latestReportVisited?${paramsToString({ storeId: sellerStoreId(), storesPrimaryKey })}`,
  });

  return res.data;
};

export const addInstallStoreSetup = async (data: { code: string, shop: string }) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/admin/addInstallStore?${paramsToString(data)}`,
  });

  return res.data;
};
export const getFreshChatInfo = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/freshchat?${paramsToString({
      storeId: sellerStoreId(),
    })}`,
  })
  return res.data;
}
export const setFreshChatInfo = async (restoreId: any) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/freshchat?${paramsToString({
      storeId: sellerStoreId(),
      restoreId
    })}`,
  })
  return res.data;
}

export const getCollectionVideos = async ({ collectionId, rank, limit }: { collectionId: number; rank?: number; limit?: number }) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/collectionVideos/${collectionId}?${paramsToString({
      ...(rank || rank === 0 ? { rank } : {}),
      limit: (limit || limit === 0) ? limit : 10,
    })}`,
  });

  return res.data;
};

export const getAllCollectionIds = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/collections/all`,
  });

  return res.data;
};

export const getDiscoveryFeed = async ({ limit, offset }: { limit: number; offset: number }) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/clout/discoveryFeed?${paramsToString({
      offset,
      limit,
    })}`,
  });

  return res.data;
};

export const getProductsForImageSearch = async (data: any, offset: number, limit: number, tilesInRow: number = 5, showBanner: boolean = true, videoOffset: number | null = null) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/products/imageSearch?offset=${offset}&limit=${limit}&tilesInRow=${tilesInRow}&showBanner=${showBanner}${videoOffset ? '&videoOffset=' + videoOffset : ''}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
  return res.data;
}

export const getVideoProductsForImageSearch = async (data: any, offset: number, limit: number) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/products/imageSearch/video?offset=${offset}&limit=${limit}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
  return res.data;
}

export const pinCodeLevelTagDownloadByProductIdAPI = async (productId: number) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/products/${productId}/pincodeLevelTagDownload`,
  });

  return res.data;
};

export const getStateLevelTagFile = async ({ productId }: { productId: number }) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/download/${productId}`,
    responseType: 'blob',
  });

  return res.data;
};
